import React from "react";

const Section = () => {
  return (
    <div className="section">
      {/* <div className="inner-section">
        <div className="section-text">
          <h5>For retail tradeors</h5>
          <h1>Prefered by traders with fully customisable API</h1>
          <p>
            Traders on our platform experience a seamless and efficient trading
            environment, designed for their needs. Our platform prioritizes
            speed, ensuring lightning-fast trade execution without any lag.
          </p>
          <div className="button">
            <a href="/">Explore Now</a>
          </div>
        </div>
        <div className="section-image">
          <img src="globe.png" alt="" />
        </div>
      </div> */}
      <div className="card-section">
        <div className="cards">
          <div className="card">
            <img src="globe.png" alt="" />
            <h1>Self Custody</h1>
            <p>
              Seize control of your finances with self-custodial savings built for both retail and season trader.
            </p>
            <a href="/">learn &rarr;</a>
          </div>
          <div className="card">
            <img src="2.png" alt="" />
            <h1>DeFi Simplified</h1>
            <p>
              SeCuWa uses some of the new standard in the blockchain ecosystem
              to abstract complex deFi flow, and provide you with simplified
              process to earn more on your money
            </p>
            <a href="/">learn &rarr;</a>
          </div>
          <div className="card">
            <img src="3.png" alt="" />
            <h1>All in-one-DeFi App</h1>
            <p>
              SeCuWa is an all in one deFi Savings app, that streamline your
              deFi savings experience using some of the new technology and
              breakthrough in the blockchain space
            </p>
            <a href="/">learn &rarr;</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
