import React from "react";
import "../firebaseConfig";
import {  addDoc, collection, initializeFirestore } from "firebase/firestore";
import { fApp } from "../firebaseConfig";

const Container = () => {
  // const db = getFirestore();
  const db = initializeFirestore(fApp, {
    experimentalForceLongPolling: true
  })
  

  const [subscribeField, setSubscribeField] = React.useState('');

  async function handleSubscribe(e) {
    e.preventDefault();
    if ((subscribeField !== undefined) | "") {
      //add email to firestore
      await addDoc(collection(db, "waitList"), {
        email: subscribeField,
      });
      setSubscribeField(" ");
      alert("You have successfully subscribe");
    } else {
      alert("Please enter a valid email");
    }
  }

  return (
    <div>
      <div className="container">
        <div className="text">
        {/* <h3>Earn More</h3> */}
          <h1>Earn and Save </h1>
          <h3>with </h3>
          <h1>Self Custody </h1>
          <p>
            SeCuWa is an innovative platform that offers users a streamlined
            method to generate earnings through renowned DeFi protocols like
            Aave and Balancer.
          </p>
          <div className="newsletter">
            <div className="news-right">
              <p>
                Be the First to Know when we launch: Subscribe for Exclusive
                Updates
              </p>
              <form action="">
                <input
                  type="email"
                  name="email"
                  value={subscribeField}
                  onChange={(value) => setSubscribeField(value.target.value)}
                />
                <button onClick={handleSubscribe}>Subscribe</button>
              </form>
            </div>

            {/* <form action="">
                
              <input
                type="email"
                name="email"
                onChange={(value) => setSubscribeField(value.target.value)}
              />
              <button onClick={handleSubscribe}>Subscribe</button>
            </form> */}
          </div>
        </div>
        <div className="image">
          <img src="crypto1.png" alt="" />
        </div>
      </div>
      <div className="partners">
        <span>
          <img src="web3Auth.png" alt="" />
        </span>
        <span>
          <img src="safe.png" alt="" />
        </span>
        <span>
          <img src="biconomy.png" alt="" />
        </span>
        <span>
          <img src="polygon.png" alt="" />
        </span>
        <span>
          <img src="Aave.png" alt="" />
        </span>
        <span>
          <img src="balancer.svg" alt="" />
        </span>
      </div>
    </div>
  );
};

export default Container;
