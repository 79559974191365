import React from "react";

const FAQ = () => {
  return (
    <div className="faq">
      <h1>Frequently Asked Questions</h1>
      <p>Have question? We are here to help</p>
      <section className="services">
        <div className="service-right">
          <div className="qa">
            <input type="checkbox" id="collapsible-head-1" />
            <label for="collapsible-head-1">What is SeCuWa?</label>
            <div className="collapsible-text-1">
              <p>
                SeCuWa stands for Self-Custody Wallet, representing an
                innovative platform that offers users a streamlined method to
                generate earnings through renowned DeFi protocols. SeCuWa is
                designed to empower individuals with full control over their
                digital assets, providing a secure and user-friendly environment
                for engaging with blue-chip DeFi protocols. Through SeCuWa,
                users can seamlessly navigate the complexities of decentralized
                finance, making it an accessible and efficient option for those
                looking to explore and benefit from the opportunities presented
                by top-tier DeFi projects.
              </p>
            </div>
          </div>
          <div className="qa">
            <input type="checkbox" id="collapsible-head-2" />
            <label for="collapsible-head-2">What is self-custody?</label>
            <div class="collapsible-text-2">
              <p>
                Self-custody refers to the practice of individuals personally
                holding and managing their own digital assets, typically within
                a digital wallet. In the context of SeCuWa, it means that you,
                as the user, have complete control over your cryptocurrency
                without relying on a third-party custodian. This enhances
                security, privacy, and ownership of your assets. With
                self-custody, you are the sole custodian of your digital wealth,
                reducing the reliance on external entities for safeguarding your
                funds.
              </p>
            </div>
          </div>
          <div className="qa">
            <input type="checkbox" id="collapsible-head-3" />
            <label for="collapsible-head-3">
              Why is SeCuWa different from other Savings and Crypto Wallets?
            </label>
            <div class="collapsible-text-3">
              <p>
                SeCuWa sets itself apart from other crypto saving apps through
                our Self-Custody and streamline approach to DeFi, ensuring that
                you retain full control over your assets. SeCuWa simplifies the
                trading process, enabling you to easily trade your USD or Crypto
                in blue-chip DeFi protocols such as Aave and Balancer. Unlike
                other options, you have clear visibility into where your crypto
                is allocated, the anticipated returns, and the associated risks.
                To put it simply, SeCuWa provides the most straightforward and
                transparent method to grow your crypto assets, empowering you
                with control and knowledge throughout the trading journey.
              </p>
            </div>
          </div>
          {/* <div className='qa'>
                    <input type="checkbox" id="collapsible-head-4" />
                    <label for="collapsible-head-4">What chain do txn support?</label>
                    <div class="collapsible-text-4">
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reprehenderit odit similique doloremque
                            ab unde. Placeat recusandae nobis voluptate atque, fugit itaque nam quos.</p>
                    </div>
                </div>
                <div className='qa'>
                    <input type="checkbox" id="collapsible-head-5" />
                    <label for="collapsible-head-5">What OS is TN compatible with?</label>
                    <div class="collapsible-text-5">
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reprehenderit odit similique doloremque
                            ab unde. Placeat recusandae nobis voluptate atque, fugit itaque nam quos.</p>
                    </div>
                </div>
                <div className='qa'>
                    <input type="checkbox" id="collapsible-head-6" />
                    <label for="collapsible-head-6">Is TXN safe to use?</label>
                    <div class="collapsible-text-6">
                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Reprehenderit odit similique doloremque
                            ab unde. Placeat recusandae nobis voluptate atque, fugit itaque nam quos.</p>
                    </div>
                </div> */}
        </div>
      </section>
    </div>
  );
};

export default FAQ;
