import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="bulb">
        <div className="pic">
          <img src="bulb.png" alt="" />
          <h1>Grow Your Money with SeCuWa</h1>
        </div>
        <div className="footer-text">
          <p>
            Discover the power of Decentralized Finance (DeFi) with SeCuWa. Our
            platform empowers you to maximize your savings and tradings, guiding
            you towards a more prosperous future. Whether you're a seasoned
            trader or just starting to explore the world of DeFi and
            Blockchain, SeCuWa offers personalized solutions tailored to your
            needs. From high-yield stablecoin to diversified trading portfolios,
            SeCuWa is here to help you unleash your financial potential.
          </p>
          {/* <a href="/">Get Started</a> */}
        </div>
      </div>
      <div className="newsletter">
        {/* <div className='news-left'>
                <h4>Subscribe</h4>
                <h1>Subscribe To Get Latest <br />Update From Us</h1>
                <span className='curve'><img src="curve.png" alt="" /></span>
            </div> */}
        {/* <div className='news-right'>
                <p>Be the First to Know when we launch: Subscribe for Exclusive Updates</p>
                <form action="">
                    <input type="email" name='email' />
                    <button>Subscribe</button>
                </form>
            </div> */}
      </div>
      <div className="footer-menu">
        <h1>SeCuWa</h1>
        {/* <ul className='footer-inner-nav'>
                <li><a href="#hub">Hub</a></li>
                <li><a href="#mint">Mint</a></li>
                <li><a href="#features">Features</a></li>
                <li><a href="#socials">Socials</a></li>
            </ul> */}
      </div>
      <hr />

      <div>
        <p className="text-center">
          &#169; Copyright 2023-2028 | All Right Reserved |
        </p>
      </div>
    </div>
  );
};

export default Footer;
